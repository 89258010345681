html,
body,
.app {
  height: 100vh;
  background: #eee;
  padding-left: 1em;
  /*padding-bottom: 1em; */
}

.ui.form .field .ui.input input.inputError {
  border-color: #e0b4b4;
  background-color: #fff6f6;
  /* border-color: blue; #//  */
  color: #9f3a38;
}

.ui.form .field .ui.input input.inputError::placeholder {
  color: #e0b4b4;
}

.messages {
  overflow-y: scroll;
  height: 65vh;
}

.messages__form {
  position: fixed !important;
  bottom: 1rem;
  margin-left: 340px !important;
  left: 0;
  right: 1rem;
  z-index: 200;
}

.message__self {
  border-left: 2px solid orange;
  padding-left: 8px;
}

.message__image {
  padding: 1rem;
}

.progress__bar {
  margin: 0.3rem 0 0 0 !important;
}

.ui.menu .menu.channel_menu {
  margin-top: 20px;
}

.color__container {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 35px;
  border-radius: 3px;
}

.color__square {
  width: 35px;
  height: 35px;
}

.color__overlay {
  width: 85px;
  height: 35px;
  transform: rotate(225deg);
}

.user__typing {
  font-style: italic;
  font-weight: bold;
  margin-right: 3px;
}

.typing {
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

/* Skeleton.js */
.skeleton {
  position: relative;
  overflow: hidden;
  height: 40px;
  margin-bottom: 10px;
}

.skeleton::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  animation: sweep 2s infinite;
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.4),
    transparent
  );
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.skeleton__avatar {
  height: 35px;
  width: 35px;
  border-radius: 3px;
  background-color: rgba(58, 57, 57, 0.3);
}

.skeleton__author {
  background-color: rgba(58, 57, 57, 0.3);
  width: 120px;
  height: 10px;
  border-radius: 3px;
  position: absolute;
  bottom: 30px;
  left: 40px;
  right: 0;
}

.skeleton__details {
  background-color: rgba(58, 57, 57, 0.3);
  height: 20px;
  border-radius: 3px;
  position: absolute;
  bottom: 5px;
  left: 40px;
  right: 20px;
}
